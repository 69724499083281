import * as React from 'react';
import Paragraph from 'components/text/Paragraph';
import { cva, cx, VariantProps } from 'class-variance-authority';

interface BaseProps {
  label?: string;
  className?: string;
  onClick?: () => void;
  color?: 'main' | 'subtle' | 'mainLight' | 'blur';
  children?: React.ReactNode;
  variant: 'mini' | 'small' | 'default';
}

type ChipProps = BaseProps & VariantProps<typeof paragraphVariants>;

export const paragraphVariants = cva('Chip flex rounded-button', {
  variants: {
    color: {
      subtle: 'bg-neutral-100 dark:bg-neutral-900 text-neutral-900 dark:text-white',
      main: 'bg-primary !text-white',
      blur: 'bg-white/20 text-white dark:bg-slate-50/20 dark:text-neutral-900 backdrop-blur-[6px]',
      mainLight: 'bg-primary-lightest text-primary'
    },
    variant: {
      mini: 'text-caption px-3 py-1',
      small: 'text-sm px-4 py-2',
      default: 'text-base px-4 py-3'
    }
  },
  defaultVariants: {
    color: 'subtle',
    variant: 'default'
  }
});

const Chip: React.FC<ChipProps> = ({ className, label, color, onClick, children, variant }) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <Paragraph
      element="div"
      className={cx(className, paragraphVariants({ color, variant }))}
      onClick={handleClick}
    >
      {children}
      {label}
    </Paragraph>
  );
};

export default Chip;
