import * as React from 'react';
import { useFeaturedList } from 'api/reactQuery/featuredItems';
import { FEATURED_SECTION_LIST } from 'utils/contentful/featuredList';
import Paragraph from 'components/text/Paragraph';
import Heading from 'components/text/Heading';
import { useTranslation } from 'next-i18next';
import ShopInlineFeaturedSectionItem from 'containers/Shop/ShopInlineFeaturedSection/ShopInlineFeaturedSectionItem';
import { formatFeaturedSectionItem } from 'utils/featuredLIst';
import MobileItemCarousel from 'components/Carousel/MobileItemCarousel';
import View from 'components/View';

const MAX_NUMBER_OF_INLINE_ITEMS = 4;

const list_id = 'home_featured_items';

const HomeInlineFeaturedSection: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useFeaturedList(FEATURED_SECTION_LIST.HOME_INLINE);
  // always show 4 items, business wants us to limit it on frontend side
  // because we can't do it on contentful for a specific entry type (SHOP_INLINE)
  const limitedData = data ? data.slice(0, MAX_NUMBER_OF_INLINE_ITEMS) : [];

  if (!limitedData?.length) return null;
  return (
    <div className="HomeInlineFeaturedSection w-full py-24 sm:py-32 overflow-hidden">
      <View>
        <div className="w-full text-left sm:text-center sm:max-w-[650px] mx-auto">
          <Heading className="!leading-[1.2] md:!text-[48px] !text-2xl" variant="h2">
            {t('home.featured.title')}
          </Heading>
          <Paragraph className="mt-2 md:mt-4" variant="body2">
            {t('home.featured.description')}
          </Paragraph>
        </div>

        {/* desktop */}
        <div className="hidden sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 my-3 gap-x-4 sm:gap-x-16 gap-y-9 sm:gap-y-16 mt-16">
          {limitedData.map((item, i) => (
            <ShopInlineFeaturedSectionItem
              listId={list_id}
              key={`${list_id}-${i}`}
              index={i}
              {...formatFeaturedSectionItem(item)}
            />
          ))}
        </div>
      </View>
      {/* mobile */}
      <MobileItemCarousel className="block sm:hidden mt-12">
        {limitedData.map((item, i) => (
          <ShopInlineFeaturedSectionItem
            listId={list_id}
            key={`${list_id}-${i}`}
            index={i}
            {...formatFeaturedSectionItem(item)}
          />
        ))}
      </MobileItemCarousel>
    </div>
  );
};

export default HomeInlineFeaturedSection;
