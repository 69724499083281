import { ICashbackType } from '@poinz/api';
import clsx from 'clsx';
import Chip from 'components/Chip';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import * as React from 'react';
import { SHOP_IMAGE_PLACEHOLDER } from 'utils/image';
import Paragraph from 'components/text/Paragraph';
import { formatCashbackValue } from 'utils/parse';
import Heading from 'components/text/Heading';
import Link from 'components/Link';
import { ENTITY_TYPE, ShoppingFeaturedSectionItem, getItemLink } from 'utils/featuredLIst';
import analytics, { ANALYTIC_EVENTS, formatAnalyticItem } from 'utils/firebase/analytics';

const LOGO_SIZE = 200;

const ShopInlineFeaturedSectionItem: React.FC<
  Partial<ShoppingFeaturedSectionItem> & { index: number }
> = item => {
  const {
    isOffline,
    isOnline,
    logo,
    name,
    slug,
    entityType,
    cashback,
    listId,
    multipleCashbacks,
    index,
    businessName
  } = item;
  const { t } = useTranslation();

  const handleLogSelectItem = () => {
    analytics.logEvent(ANALYTIC_EVENTS.SELECT_ITEM, {
      item_list_id: listId,
      items: [formatAnalyticItem(item)],
      item_index: index
    });
  };

  const renderCashbackChips = () => {
    const labels = [];
    if (entityType === ENTITY_TYPE.DEAL) {
      labels.push(t('shop.badge.deal'));
    } else if (entityType === ENTITY_TYPE.GIFT_CARD_LINE) {
      labels.push(t('shop.badge.giftCard'));
    } else if (entityType === ENTITY_TYPE.AFFILIATE) {
      if (isOnline) {
        labels.push(t('shop.affiliate.badge.online'));
      }
      if (isOffline) {
        labels.push(t('shop.affiliate.badge.local'));
      }
    }

    return (
      <div className="flex flex-wrap gap-2 mt-6 sm:mt-8">
        {labels.map(label => (
          <Chip key={`featured-card-label-${label}`} variant="mini" label={label as string} />
        ))}
      </div>
    );
  };

  const renderCashback = () => {
    if (!cashback) {
      return null;
    }

    if (typeof cashback === 'number') {
      return (
        <Paragraph variant="subtitle" className="font-normal">
          {t('shop.cashback', {
            value: formatCashbackValue(cashback, ICashbackType.PERCENTAGE, 0)
          })}
        </Paragraph>
      );
    }
    return (
      <Paragraph className="flex flex-wrap gap-x-1 md:gap-x-2 text-subtitle whitespace-nowrap">
        {!!cashback.campaign && (
          <span className="line-through !font-normal">
            {formatCashbackValue(cashback.cashbackValue, cashback.type, 0)}
          </span>
        )}
        <span className={clsx(!!cashback.campaign && 'text-primary font-semibold', 'font-normal')}>
          {t(multipleCashbacks ? 'shop.affiliate.upToCashback' : 'shop.cashback', {
            value: formatCashbackValue(
              cashback.campaign?.value || cashback.cashbackValue,
              cashback.campaign?.type || cashback.type,
              0
            )
          })}
        </span>
      </Paragraph>
    );
  };

  const renderCashbackName = () => {
    if (!cashback) {
      return null;
    }
    let cashbackName = '';

    if (typeof cashback === 'number') {
      if (entityType === ENTITY_TYPE.DEAL) {
        cashbackName = t('shop.casbackNameForDeal');
      } else if (entityType === ENTITY_TYPE.GIFT_CARD_LINE) {
        cashbackName = name || t('shop.casbackNameForGiftCard');
      }
    } else {
      if (entityType === ENTITY_TYPE.AFFILIATE) {
        cashbackName = cashback?.nameLocalized || t('shop.affiliate.fallbackCashbackName');
      }
    }

    return cashbackName;
  };

  return (
    <div className="w-[260px] sm:w-auto border border-neutral-900 sm:border-0 mr-4 sm:mr-0 px-6 py-[30px] sm:p-0 rounded-2xl sm:rounded-none">
      <Link href={getItemLink(entityType, slug) || ''} onClick={handleLogSelectItem}>
        <Image
          className="object-contain w-auto h-12"
          src={logo ? logo : SHOP_IMAGE_PLACEHOLDER}
          alt={`Logo - ${name}`}
          width={LOGO_SIZE}
          height={LOGO_SIZE}
        />
        {renderCashbackChips()}
        <Heading
          variant="h4"
          className="!text-subtitle whitespace-nowrap overflow-hidden text-ellipsis mt-4"
        >
          {businessName}
        </Heading>
        {renderCashback()}
        <Paragraph className="text-body1 whitespace-nowrap overflow-hidden text-ellipsis mt-3">
          {renderCashbackName()}
        </Paragraph>
      </Link>
    </div>
  );
};

export default ShopInlineFeaturedSectionItem;
